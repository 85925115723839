import { useState, Dispatch, useEffect, Fragment } from 'react';
import Image from 'next/legacy/image';
import { MenuCategoryItem, MenuItem, MenuMarketItem, MenuResponse } from 'types';
import { stringifyQueryFilters } from 'utils/formatters';
import { getSafeArrayFromObject, sortByTextField } from 'utils/services';
import { DropMenu } from 'components/Dropdown';
import { NextLink } from 'components/NextLink';

import cx from 'classnames';
import styles from './SubHeader.module.scss';

export type BaseSubHeaderProps = {
  menuData: MenuResponse;
  isOpened: boolean;
  setIsOpened?: Dispatch<boolean>;
  filters?: Record<string, string | number | boolean>;
  withBadge?: boolean;
};

const findMarket = (id: string | number | null, markets: MenuMarketItem[] | MenuItem[]) => {
  if (typeof id === 'number') {
    return markets.find((market) => market.id === id);
  }
  return markets.find((market) => market.title === id);
};

export const BaseSubHeader = ({ menuData, isOpened, setIsOpened, filters, withBadge, appliedFilters }: BaseSubHeaderProps) => {
  const [markets, allMarkets] = getSafeArrayFromObject(menuData, ['markets', 'all_markets']);
  const [activeItem, setActiveItem] = useState<string | null>(null);
  const [marketContent, setMarketContent] = useState<MenuMarketItem | null>();
  const [regularContent, setRegularContent] = useState<MenuItem[] | null>();
  const [dropdownValue, setDropdownValue] = useState<number | undefined>();

  const activitiesExpanded = activeItem === 'activities';
  const brandExpanded = activeItem === 'brands';

  useEffect(() => {
    if (activeItem === 'activities' || activeItem === 'brands') {
      setDropdownValue(undefined);
    }
  }, [activeItem]);

  const cutRegularItemsIfNeeded = (array: MenuItem[]) => {
    const maxLength = 26;
    if (array.length <= maxLength) {
      return array;
    }
    return [...array.slice(0, maxLength)];
  };

  const trackDropdownMarketChange = (id: string | number | null) => {
    if (id === 0) {
      document.location.assign('/gear');
    } else {
      const marketSlug = findMarket(id, allMarkets)?.slug;
      document.location.assign(`/gear?status=active&markets=["${marketSlug}"]`);
    }
  };

  const trackMarketChanges = (id: string | number | null, selectorValue?: number) => {
    setDropdownValue(selectorValue);
    setRegularContent(null);
    const currentMarket = findMarket(id, markets);
    if (currentMarket?.title === activeItem) {
      setActiveItem(null);
      setMarketContent(null);
    } else {
      setActiveItem(currentMarket?.title || null);
      setMarketContent(currentMarket as MenuMarketItem);
    }
  };

  const trackRegularChanges = (type: 'activities' | 'brands') => {
    setMarketContent(null);
    if (activeItem === type) {
      setActiveItem(null);
      setRegularContent(null);
    } else {
      const currentSet = cutRegularItemsIfNeeded(menuData[type] || []);
      setActiveItem(type);
      setRegularContent(currentSet);
    }
  };

  const renderLink = (item: MenuItem) => (
    <NextLink
      key={`link-${item.id}`}
      href={`/gear?${activeItem}=${JSON.stringify([item.slug])}`}
      title={item.title}
      useDirectLink={true}
    />
  );

  const renderMarketLink = (market: MenuMarketItem, category: MenuCategoryItem) => {
    const filterParams = `?markets=["${market.slug}"]&category=${category.slug}`;
    const additionalFilters = filters ? stringifyQueryFilters(filters) : [];

    return (
      <NextLink
        key={`cat-${category.id}`}
        href={`/gear${filterParams}&${additionalFilters.join('&')}`}
        title={category.title}
        useDirectLink={true}
      />
    );
  };

  const renderMarketItem = (market: MenuMarketItem) => {
    const sortedCategories = sortByTextField(market.categories);

    return (
      <div className={styles.marketTypes}>{sortedCategories.map((category) => renderMarketLink(market, category))}</div>
    );
  };

  const renderRegularItem = () => (
    <div className={styles.regularItems}>
      <div className={styles.extendedMenuLinks}>{regularContent && regularContent.map(renderLink)}</div>
    </div>
  );

  const renderAllItemsLink = (link: string, title: string) => (
    <div className={styles.allLinkItem}>
      <div className={styles.extendedMenuLinks}>
        <NextLink href={link} title={title} useDirectLink={true} />
      </div>
    </div>
  );

  const renderFinalLink = () => {
    if (!!marketContent) {
      return renderAllItemsLink(`/gear?markets=["${marketContent?.slug}"]`, `All gear for ${marketContent?.title}`);
    }
    if (brandExpanded) {
      return renderAllItemsLink('/brands', 'All Brands');
    }
    if (activitiesExpanded) {
      renderAllItemsLink('/gear', 'Gear for All Activities');
    }
  };

  const handleCloseExtendedMenu = () => {
    setActiveItem(null);
    setMarketContent(null);
    setRegularContent(null);
  };

  const renderExtendedMenu = () => (
    <div className={styles.extendedMenuBrandWrapper}>
      <div className={styles.extendedMenuBrandContent}>
        {!!marketContent ? renderMarketItem(marketContent) : renderRegularItem()}
        {renderFinalLink()}
      </div>
    </div>
  );

  return (
    <>
      <div className={styles.border} />
      <nav
        className={cx(styles.menu, {
          [styles.open]: isOpened,
          [styles.close]: !isOpened,
          [styles.withBadge]: withBadge,
        })}
      >
        <div className={styles.container}>
          <div className={cx(styles.sideMenu, styles.sellTypeCell, appliedFilters.type==='sell' && styles.sellActive)}>
            <NextLink href={`/gear?type=sell`}>
              <p className={cx(styles.submenuItem, appliedFilters.type==='sell' && styles.textActive)}>BUY</p>
            </NextLink>
          </div>
          <div className={cx(styles.sideMenu, styles.sellTypeCell, appliedFilters.type==='rent' && styles.sellActive)}>
            <NextLink href={`/gear?type=rent`}>
              <p className={cx(styles.submenuItem, appliedFilters.type==='rent' && styles.textActive)}>RENT</p>
            </NextLink>
          </div>
          <ul>
            {/*<div className={styles.sideMenu} onClick={handleCloseExtendedMenu}>*/}
            {/*  <DropMenu*/}
            {/*    menuTitle="All markets"*/}
            {/*    options={allMarkets}*/}
            {/*    onChange={trackDropdownMarketChange}*/}
            {/*    value={dropdownValue}*/}
            {/*  />*/}
            {/*</div>*/}
            <div className={styles.dropdownWrapper}>
              {markets.map((market: MenuMarketItem) => {
                const isExpanded = activeItem === market.title;
                return (
                  <Fragment key={market.slug}>
                    <li onClick={() => trackMarketChanges(market.title)}>
                      <p className={cx(styles.submenuItem, { [styles.active]: isExpanded })}>{market.title}</p>
                      <div className={styles.arrow}>
                        <Image
                          src={`/icons/arrows/${isExpanded ? 'ArrowDownDark.svg' : 'ArrowRightDark.svg'}`}
                          height={24}
                          width={24}
                          alt="arrow"
                        />
                      </div>
                    </li>
                    <nav
                      className={cx(styles.mobileExtendedMenu, {
                        [styles.mobileExtendedMenuOpened]: isExpanded,
                        [styles.withBadge]: withBadge,
                      })}
                    >
                      {isExpanded && renderExtendedMenu()}
                    </nav>
                  </Fragment>
                );
              })}
              {!!menuData.activities?.length && (
                <>
                  <li onClick={() => trackRegularChanges('activities')}>
                    <p className={cx(styles.submenuItem, { [styles.active]: activitiesExpanded })}>Activities</p>
                    <div className={styles.arrow}>
                      <Image
                        src={`/icons/arrows/${activitiesExpanded ? 'ArrowDownDark.svg' : 'ArrowRightDark.svg'}`}
                        height={24}
                        width={24}
                        alt="arrow"
                      />
                    </div>
                  </li>
                  <nav
                    className={cx(styles.mobileExtendedMenu, {
                      [styles.mobileExtendedMenuOpened]: activitiesExpanded,
                      [styles.withBadge]: withBadge,
                    })}
                  >
                    {activitiesExpanded && renderExtendedMenu()}
                  </nav>
                </>
              )}
              {!!menuData.brands?.length && (
                <>
                  <li onClick={() => trackRegularChanges('brands')}>
                    <p className={cx(styles.submenuItem, { [styles.active]: brandExpanded })}>Brands</p>
                    <div className={styles.arrow}>
                      <Image
                        src={`/icons/arrows/${brandExpanded ? 'ArrowDownDark.svg' : 'ArrowRightDark.svg'}`}
                        height={24}
                        width={24}
                        alt="arrow"
                      />
                    </div>
                  </li>
                  <nav
                    className={cx(styles.mobileExtendedMenu, {
                      [styles.mobileExtendedMenuOpened]: brandExpanded,
                      [styles.withBadge]: withBadge,
                    })}
                  >
                    {brandExpanded && renderExtendedMenu()}
                  </nav>
                </>
              )}
            </div>
            <div className={styles.underListWrapper}></div>
          </ul>
        </div>
      </nav>
      <div
        className={cx(styles.extendedMenu, {
          [styles.openExtended]: !!marketContent || regularContent?.length,
          [styles.withBadge]: (!!marketContent || regularContent?.length) && withBadge,
        })}
      >
        {!!(marketContent || regularContent?.length) && renderExtendedMenu()}
      </div>
      {isOpened && setIsOpened && <div className={styles.cover} onClick={() => setIsOpened(false)} />}
    </>
  );
};
